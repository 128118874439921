var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "70%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "保单号", prop: "insuranceNo" } },
            [
              _c("a-input", {
                attrs: { disabled: _vm.onlyRead, placeholder: "请输入保单号" },
                model: {
                  value: _vm.form.insuranceNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "insuranceNo", $$v)
                  },
                  expression: "form.insuranceNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆自编号", prop: "vehicleNo" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    "show-search": "",
                    disabled: _vm.onlyRead,
                    allowClear: "",
                    placeholder: "请输入车辆自编号模糊查找",
                    "default-active-first-option": false,
                    "show-arrow": false,
                    "filter-option": false,
                    "not-found-content": null,
                    loading: _vm.loading,
                  },
                  on: {
                    search: _vm.handleVehicleNoSearch,
                    change: _vm.handleVehicleNoChange,
                  },
                  model: {
                    value: _vm.form.vehicleNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "vehicleNo", $$v)
                    },
                    expression: "form.vehicleNo",
                  },
                },
                _vm._l(_vm.vehicleNoArray, function (d) {
                  return _c(
                    "a-select-option",
                    { key: d.vehicleNo, attrs: { value: d.vehicleNo } },
                    [_vm._v(" " + _vm._s(d.vehicleNo) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆车牌号", prop: "licensePlateNum" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    "show-search": "",
                    disabled: _vm.onlyRead,
                    allowClear: "",
                    placeholder: "请输入车辆车牌号模糊查找",
                    "default-active-first-option": false,
                    "show-arrow": false,
                    "filter-option": false,
                    "not-found-content": null,
                    loading: _vm.loading,
                  },
                  on: {
                    search: _vm.handleLicensePlateNumSearch,
                    change: _vm.handleLicensePlateNumChange,
                  },
                  model: {
                    value: _vm.form.licensePlateNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "licensePlateNum", $$v)
                    },
                    expression: "form.licensePlateNum",
                  },
                },
                _vm._l(_vm.vehicleNoArray, function (d) {
                  return _c(
                    "a-select-option",
                    {
                      key: d.licensePlateNum,
                      attrs: { value: d.licensePlateNum },
                    },
                    [_vm._v(" " + _vm._s(d.licensePlateNum) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "VIN码", prop: "vinNo" } },
            [
              _c("a-input", {
                attrs: { disabled: _vm.onlyRead, placeholder: "请输入VIN码" },
                model: {
                  value: _vm.form.vinNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vinNo", $$v)
                  },
                  expression: "form.vinNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "发动机号", prop: "engineNo" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.onlyRead,
                  placeholder: "请输入发动机号",
                },
                model: {
                  value: _vm.form.engineNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "engineNo", $$v)
                  },
                  expression: "form.engineNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "初次登记日期", prop: "firstRegisterDate" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: _vm.onlyRead,
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.firstRegisterDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "firstRegisterDate", $$v)
                  },
                  expression: "form.firstRegisterDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "核定载客", prop: "seatNum" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.onlyRead,
                  placeholder: "请输入核定载客",
                },
                model: {
                  value: _vm.form.seatNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "seatNum", $$v)
                  },
                  expression: "form.seatNum",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "使用性质", prop: "useNature" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.onlyRead,
                  placeholder: "请输入使用性质",
                },
                model: {
                  value: _vm.form.useNature,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "useNature", $$v)
                  },
                  expression: "form.useNature",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "被保险人", prop: "insured" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.onlyRead,
                  placeholder: "请输入被保险人",
                },
                model: {
                  value: _vm.form.insured,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "insured", $$v)
                  },
                  expression: "form.insured",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "保险公司名称", prop: "name" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.onlyRead,
                  placeholder: "请输入保险公司名称",
                },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "保单类型", prop: "insurancePolicyType" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    disabled: _vm.onlyRead,
                    placeholder: "请选择保单类型",
                  },
                  model: {
                    value: _vm.form.insurancePolicyType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "insurancePolicyType", $$v)
                    },
                    expression: "form.insurancePolicyType",
                  },
                },
                _vm._l(_vm.insurancePolicyTypeOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "实际投保日期", prop: "actualInsureDate" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: _vm.onlyRead,
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.actualInsureDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "actualInsureDate", $$v)
                  },
                  expression: "form.actualInsureDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "保险总费用", prop: "insuranceCostTotal" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入保险总费用" },
                model: {
                  value: _vm.form.insuranceCostTotal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "insuranceCostTotal", $$v)
                  },
                  expression: "form.insuranceCostTotal",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "起始日期", prop: "insuranceStartDate" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: _vm.onlyRead,
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.insuranceStartDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "insuranceStartDate", $$v)
                  },
                  expression: "form.insuranceStartDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "结束日期", prop: "insuranceEndDate" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: _vm.onlyRead,
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.insuranceEndDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "insuranceEndDate", $$v)
                  },
                  expression: "form.insuranceEndDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "保单上传扫描件或电子元件上传",
                prop: "policyPhoto",
              },
            },
            [
              _c("file-upload", {
                attrs: { folder: _vm.folder, type: "image" },
                model: {
                  value: _vm.form.policyPhoto,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "policyPhoto", $$v)
                  },
                  expression: "form.policyPhoto",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  !_vm.onlyRead
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(" 保存 ")]
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }