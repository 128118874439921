<template>
  <a-drawer width="70%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="保单号" prop="insuranceNo">
        <a-input :disabled="onlyRead" v-model="form.insuranceNo" placeholder="请输入保单号" />
      </a-form-model-item>
      <a-form-model-item label="车辆自编号" prop="vehicleNo">
        <a-select
          show-search
          :disabled="onlyRead"
          allowClear
          placeholder="请输入车辆自编号模糊查找"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleVehicleNoSearch"
          @change="handleVehicleNoChange"
          :loading="loading"
          v-model="form.vehicleNo"
        >
          <a-select-option v-for="d in vehicleNoArray" :key="d.vehicleNo" :value="d.vehicleNo">
            {{ d.vehicleNo }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="车辆车牌号" prop="licensePlateNum">
        <a-select
          show-search
          :disabled="onlyRead"
          allowClear
          placeholder="请输入车辆车牌号模糊查找"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleLicensePlateNumSearch"
          @change="handleLicensePlateNumChange"
          :loading="loading"
          v-model="form.licensePlateNum"
        >
          <a-select-option v-for="d in vehicleNoArray" :key="d.licensePlateNum" :value="d.licensePlateNum">
            {{ d.licensePlateNum }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="VIN码" prop="vinNo">
        <a-input :disabled="onlyRead" v-model="form.vinNo" placeholder="请输入VIN码" />
      </a-form-model-item>
      <a-form-model-item label="发动机号" prop="engineNo">
        <a-input :disabled="onlyRead" v-model="form.engineNo" placeholder="请输入发动机号" />
      </a-form-model-item>
      <a-form-model-item label="初次登记日期" prop="firstRegisterDate">
        <a-date-picker
          style="width: 100%"
          :disabled="onlyRead"
          v-model="form.firstRegisterDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="核定载客" prop="seatNum">
        <a-input :disabled="onlyRead" v-model="form.seatNum" placeholder="请输入核定载客" />
      </a-form-model-item>
      <a-form-model-item label="使用性质" prop="useNature">
        <a-input :disabled="onlyRead" v-model="form.useNature" placeholder="请输入使用性质" />
      </a-form-model-item>
      <a-form-model-item label="被保险人" prop="insured">
        <a-input :disabled="onlyRead" v-model="form.insured" placeholder="请输入被保险人" />
      </a-form-model-item>
      <a-form-model-item label="保险公司名称" prop="name">
        <a-input :disabled="onlyRead" v-model="form.name" placeholder="请输入保险公司名称" />
      </a-form-model-item>
      <a-form-model-item label="保单类型" prop="insurancePolicyType">
        <a-select :disabled="onlyRead" placeholder="请选择保单类型" v-model="form.insurancePolicyType">
          <a-select-option v-for="(d, index) in insurancePolicyTypeOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="实际投保日期" prop="actualInsureDate">
        <a-date-picker
          style="width: 100%"
          :disabled="onlyRead"
          v-model="form.actualInsureDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="保险总费用" prop="insuranceCostTotal">
        <a-input disabled v-model="form.insuranceCostTotal" placeholder="请输入保险总费用" />
      </a-form-model-item>
      <a-form-model-item label="起始日期" prop="insuranceStartDate">
        <a-date-picker
          style="width: 100%"
          :disabled="onlyRead"
          v-model="form.insuranceStartDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="结束日期" prop="insuranceEndDate">
        <a-date-picker
          style="width: 100%"
          :disabled="onlyRead"
          v-model="form.insuranceEndDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="保单上传扫描件或电子元件上传" prop="policyPhoto">
        <file-upload v-model="form.policyPhoto" :folder="folder" type="image"></file-upload>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button v-if="!onlyRead" type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getInsurance, addInsurance, updateInsurance } from '@/api/iot/insurance'
import debounce from 'lodash/debounce'
import { searchCarNo } from '@/api/iot/vehicleAccount'

export default {
  name: 'CreateForm',
  props: {
    insurancePolicyTypeOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data() {
    this.handleVehicleNoSearch = debounce(this.handleVehicleNoSearch, 500)
    this.handleLicensePlateNumSearch = debounce(this.handleLicensePlateNumSearch, 500)
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        insuranceNo: null,
        vehicleNo: null,
        licensePlateNum: null,
        vinNo: null,
        engineNo: null,
        firstRegisterDate: null,
        seatNum: null,
        useNature: null,
        insured: null,
        name: null,
        insurancePolicyType: null,
        actualInsureDate: null,
        insuranceStartDate: null,
        insuranceEndDate: null,
        insuranceCostTotal: null,
        policyPhoto: null,
        status: 0,
        coverages: [
          {
            insuranceCoverName: '',
            rateFloating: '',
            insuranceAmount: '',
            insuranceCost: '',
            deductible: ''
          }
        ]
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        insuranceNo: [{ required: true, message: '保单号不能为空', trigger: 'blur' }],
        vehicleNo: [{ required: true, message: '车辆自编号不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '保险公司名称不能为空', trigger: 'blur' }],
        insurancePolicyType: [{ required: true, message: '保单类型不能为空', trigger: 'blur' }],
        actualInsureDate: [{ required: true, message: '实际投保日期不能为空' }],
        insuranceStartDate: [{ required: true, message: '起始日期不能为空' }],
        insuranceEndDate: [{ required: true, message: '结束日期不能为空' }],
        policyPhoto: [{ required: true, message: '保单上传扫描件或电子元件上传不能为空' }]
      },
      folder: '',
      vehicleNoArray: [],
      onlyRead: false
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {
    'form.vehicleNo'(val) {
      console.log('form.vehicleNo', val)
      this.folder = 'vehicleAccount/' + val
    }
  },
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        insuranceNo: null,
        vehicleNo: null,
        licensePlateNum: null,
        vinNo: null,
        engineNo: null,
        firstRegisterDate: null,
        seatNum: null,
        useNature: null,
        insured: null,
        name: null,
        insurancePolicyType: null,
        actualInsureDate: null,
        insuranceStartDate: null,
        insuranceEndDate: null,
        insuranceCostTotal: null,
        policyPhoto: null,
        status: 0,
        coverages: [
          {
            insuranceCoverName: '',
            rateFloating: '',
            insuranceAmount: '',
            insuranceCost: '',
            deductible: ''
          }
        ],
        onlyRead: false
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids, type) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      if (type === 'onlyRead') {
        this.onlyRead = true
      }
      getInsurance(id).then(response => {
        if (!response.data.coverages || response.data.coverages.length === 0) {
          response.data.coverages = [
            {
              insuranceCoverName: '',
              rateFloating: '',
              insuranceAmount: '',
              insuranceCost: ''
            }
          ]
        } else {
          for (const item of response.data.coverages) {
            if (item.insuranceCoverName === '1' || item.insuranceCoverName === '5') {
              item.rateFloating = item.compulsoryRateFloating
            }
          }
        }
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    setAmount(amount) {
      console.log('amount', amount)
      this.form.insuranceCostTotal = amount
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.listRef.$refs.form.validate(valid => {
        if (valid) {
          this.$refs.form.validate(valid => {
            if (valid) {
              const form = this.formateForm()
              if (form.id !== undefined && form.id !== null) {
                updateInsurance(form).then(response => {
                  this.$message.success('修改成功', 3)
                  this.open = false
                  this.$emit('ok')
                })
              } else {
                addInsurance(form).then(response => {
                  this.$message.success('新增成功', 3)
                  this.open = false
                  this.$emit('ok')
                })
              }
            } else {
              return false
            }
          })
        }
      })
    },
    formateForm() {
      const form = JSON.parse(JSON.stringify(this.form))
      for (const item of form.coverages) {
        item.insuranceNo = form.insuranceNo
        if (item.insuranceCoverName === '1' || item.insuranceCoverName === '5') {
          item.compulsoryRateFloating = item.rateFloating
          item.rateFloating = undefined
        }
      }
      return form
    },
    handleVehicleNoSearch(value) {
      console.log('handleVehicleNoSearch', value)
      const queryParam = {
        vehicleNo: value
      }
      this.loading = true
      searchCarNo(queryParam).then(response => {
        this.vehicleNoArray = response
        this.loading = false
      })
    },
    handleVehicleNoChange(value) {
      this.form.vehicleNo = value
      // 设置车牌号
      const tmpArray = this.vehicleNoArray.filter(p => p.vehicleNo === value)
      if (tmpArray.length > 0) {
        this.form.licensePlateNum = tmpArray[0].licensePlateNum
        this.$forceUpdate()
      }
    },
    handleLicensePlateNumSearch(value) {
      console.log('handleLicensePlateNumSearch', value)
      const queryParam = {
        licensePlateNum: value
      }
      this.loading = true
      searchCarNo(queryParam).then(response => {
        this.vehicleNoArray = response
        this.loading = false
      })
    },
    handleLicensePlateNumChange(value) {
      this.form.licensePlateNum = value
      // 设置车辆自编号
      const tmpArray = this.vehicleNoArray.filter(p => p.licensePlateNum === value)
      if (tmpArray.length > 0) {
        this.form.vehicleNo = tmpArray[0].vehicleNo
        this.$forceUpdate()
      }
    }
  }
}
</script>
